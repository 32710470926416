export const CORE_BANKING_API_ENDPOINTS = {
	AUTH: {
		LOGIN: '/login?provider=DbxUserLogin',
		FEATURE_PERMISSIONS:
			'/services/data/v1/Login/operations/Users/getFeaturesAndPermissions',
		LOGOUT: '/logout?provider=DbxUserLogin',
		CLEAR_SESSION: '/services/IST/clearSession',
		REGISTER:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/bbgEnrollRetailUser',
		ACTIVATE_ACCOUNT:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/validateActivationCodeForEnrollment',
		CREATE_PASSWORD:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/UpdatePasswordForActivationFlow',
		GET_USER_CONTRACTS:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/getInfinityUserContractCustomers',
		REFRESH_TOKEN: '/refresh-token',
		VERIFY_CORE_USER:
			'/services/data/v1/Login/operations/Users_2/bbgVerifyCoreUser',
		REQUEST_RESET_PASSWORD_OTP:
			'/services/data/v1/Login/operations/Users_2/requestResetPasswordOTP',
		VERIFY_OTP:
			'/services/data/v1/Login/operations/Users_2/verifyOTPPreLogin',
		RESET_PASSWORD:
			'/services/data/v1/Login/operations/Users_2/resetUserPassword',
		RESEND_ACTIVATE_CODE:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/bbgResendActivationCode',
		VERFIFY_USER:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers/verifyExistingPassword',
		TRACK_DEVICE:
			'/services/data/v1/Login/operations/TrackDeviceRegistration/trackDeviceRegistration',
	},

	ACCOUNT: {
		GET_ACCOUNTS:
			'/services/data/v1/Holdings/operations/DigitalArrangements/bbgGetDashboardAccounts',
		GET_DETAILS:
			'/services/data/v1/Holdings/operations/ArrangementDetails/getDetails',
		GENERATE_RELEVE_RIB:
			'/services/data/v1/Releve/operations/Account/generateReleveRib',
		GENERATE_RELEVE_RIB_ACCOUNT: '/services/data/v1/Releve/objects/Account',
		GENERATE_ACCOUNT_STATEMENT:
			'/services/data/v1/Releve/operations/Account/generateAccountStatement'
	},

	BENEFICIARY: {
		CREATE_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/createPayee',
		EDIT_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/editPayee',
		DELETE_BENEFICIARY:
			'/services/data/v1/PayeeManagement/operations/Payees/deletePayee',
		GET_BENEFICIARIES_LIST:
			'/services/data/v1/PayeeManagement/operations/Payees/getPayeesList',
		GET_BENEFICIARY_NAME:
			'/services/data/v1/PayeeManagement/operations/Payee_Name/getPayeeName',
	},

	LEGAL_ENTITIES: {
		GET_LEGAL_ENTITIES:
			'/services/data/v1/Utility/operations/LegalEntity/getLegalEntities',
		UPDATE_CURRENT_LEGAL_ENTITIES:
			'/services/data/v1/Utility/operations/LegalEntity/UpdateCurrentEntityInCache',
	},

	NOTIFICATIONS: {
		GET_NOTIFICATIONS:
			'/services/data/v1/Notifications/operations/UnreadNotifications/GetMessagesNotifications',
		GET_CATEGORIES:
			'/services/data/v1/AlertsManagement/operations/Alerts/getCategories',
		GET_PREFERENCES:
			'/services/data/v1/AlertsManagement/operations/Alerts/getPreferences',
		NOTIFICATION_LIST:
			'/services/data/v1/Notifications/objects/NotificationList',
		GET_COUNT:
			'/services/data/v1/Notifications/operations/UnreadNotifications/getCount',
		UNREAD: '/services/data/v1/Notifications/objects/UnreadNotifications',
	},
	TRANSACTIONS: {
		GET_SCHEDULED_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/getUserScheduledTransactions',
		GET_RECENT_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/getRecent',
		BBG_GET_RECENT_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/bbgGetRecent',
		SEARCH_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/searchTransactions',
		BBG_SEARCH_TRANSACTIONS:
			'/services/data/v1/Holdings/operations/TransactionsList/bbgSearchTransactions',
	},
	USER: {
		GET_CONTRACTS:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/getInfinityUserContractCustomers',
		VERIFY: '/services/data/v1/Login/operations/Users_2/bbgVerifyCoreUser',
		TERMS_AND_CONDITIONS:
			'/services/data/v1/ContentManagement/operations/TermsAndConditions/getPreLogin',
		GET_POST_LOGIN:
			'/services/data/v1/ContentManagement/operations/TermsAndConditions/getPostLogin',
		CUSTOMER_TNC_FOR_LOGIN:
			'/services/data/v1/ContentManagement/operations/TermsAndConditions/createCustomerTNCForLogin',
		DEFAULT_LANGUAGE:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_1/UpdateDefaultLanguage',
	},
	BENEFICIARIES: {
		BASE_URL: '/services/data/v1/PayeeManagement/operations/Payees',
		GET_ALL: '/getPayeesList',
		EDIT: '/editPayee',
		DELETE: '/deletePayee',
		GET_PAYEE_NAME:
			'/services/data/v1/PayeeManagement/operations/Payee_Name/getPayeeName',
	},
	INFO: {
		BASE_URL: '/services/data/v1/ContentManagement/operations',
		GET_CONTACT_US: '/Information/getContactUs',
		GET_AGENCE: '/everest/api/v1/agence',
		GET_LIST:
			'/services/data/v1/ContentManagement/operations/Locations/getList',
		CREATE_FEEDBACK:
			'/services/data/v1/ContentManagement/operations/CustomerFeedback/createFeedback',
	},
	MESSAGES: {
		CREATE_MESSAGE:
			'/services/data/v1/SecureMessaging/operations/Message/createRequest',
		GET_MESSAGES:
			'/services/data/v1/SecureMessaging/operations/Message/getRequests',
		GET_MESSAGES_BY_DISCUSSION_ID:
			'/services/data/v1/SecureMessaging/operations/Message/getAllMessagesForARequest',
		UPDATE_MESSAGE:
			'/services/data/v1/SecureMessaging/operations/Message/updateRequest',
		GET_UNREAD_COUNT:
			'/services/data/v1/SecureMessaging/operations/Message/getUnreadMessageCount',
		GET_CATEGORIES:
			'/services/data/v1/SecureMessaging/operations/Message/getRequestCategory',
		CREATE_MEDIA:
			'/services/data/v1/MessageBinary/objects/media',
		CREATE_ATTACHMENT:
			'/services/data/v1/SecureMessaging/operations/Message/addAttachment'
	},
	TRANSFERS: {
		OWN_ACCOUNT_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/createOwnAccountTransfer',
		INTRA_BANK_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/createIntrabankTransfer',
		INTER_BANK_TRANSFER: '/interBankTransfer',
		GET_BANK_DATE:
			'/services/data/v1/TransactionObjects/operations/BankDate/getBankDate',
		GET_UEMOA_BANKS:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/getUemoaBanks',
		SIKA_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/createSicaTransfer',
		RTGS_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/createRtgsTransfer',
		INTERNATIONAL_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/createInternationalTransfer',
		OWN_ACCOUNT_STANDING_ORDER_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/StandingOrder/createOwnAccountStandingOrder',
		INTER_BANK_STANDING_ORDER_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/StandingOrder/createIntrabankStandingOrder',
		SIKA_STANDING_ORDER_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/StandingOrder/createSicaStandingOrder',
		INTERNATIONAL_STANDING_ORDER_TRANSFER:
			'/services/data/v1/BbgTransfers/operations/StandingOrder/createInternationalStandingOrder',
		GET_TRANSFER_HISTORY:
			'/services/data/v1/BbgTransfers/operations/OneTimeTransfer/getTransferHistory',
		GENERATE_TRANSFER_ADVICE:
		'/services/data/v1/Releve/operations/Account/generateTransferAdvice',
		GET_INSTRUCTIONS:
			'/services/data/v1/Transfers/operations/StandingInstruction/getInstructions',
		GET_ALL_PENDING_APPROVALS:
			'/services/data/v1/ApprovalsAndRequests/operations/MyApprovals/fetchAllMyPendingApprovals',
		GET_APPROVAL_HISTORY:
			'/services/data/v1/ApprovalsAndRequests/operations/MyApprovals/fetchAllMyApprovalHistory',
		GET_ALL_PENDINGS_REQUESTS:
			'/services/data/v1/ApprovalsAndRequests/operations/MyRequests/fetchAllMyPendingRequests',
		GET_REQUEST_HISTORY:
			'/services/data/v1/ApprovalsAndRequests/operations/MyRequests/fetchAllMyRequestHistory',
		APPROVE_REQUEST:
			'/services/data/v1/ApprovalsAndRequests/operations/MyApprovals/Approve',
		REJECT_REQUEST:
			'/services/data/v1/ApprovalsAndRequests/operations/MyApprovals/Reject',
		WITHDRAW_REQUEST:
			'/services/data/v1/ApprovalsAndRequests/operations/MyRequests/Withdraw',
		// Delete transfer
		TRANSFER_TO_OWN_ACCOUNTS_DELETE:
			'services/data/v1/TransactionObjects/operations/Transaction/TransferToOwnAccountsDelete',
		INTRA_BANK_ACC_FUND_TRANSFER_DELETE:
			'/services/data/v1/TransactionObjects/operations/Transaction/IntraBankAccFundTransferDelete',
		INTER_BANK_FUND_TRANSFER_DELETE:
			'/services/data/v1/TransactionObjects/operations/Transaction/InterBankFundTransferDelete',
		INTERNATIONAL_FUND_TRANSFER_DELETE:
			'/services/data/v1/TransactionObjects/operations/Transaction/InternationalFundTransferDelete',
		FETCH_BASE_CURRENCY:
			'/services/data/v1/ForeignExchange/operations/Forex/fetchBaseCurrency',
		FETCH_DASHBOARD_CURRENCY_LIST:
			'/services/data/v1/ForeignExchange/operations/Forex/fetchDashboardCurrencyList',
		FETCH_CURRENCY_RATES:
			'/services/data/v1/ForeignExchange/operations/Forex/fetchCurrencyRates',


		MASS_TRANSFER: {
			UPLOAD_FILE:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentFile/UploadFile',
			FETCH_UPLOADED_FILES:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentFile/fetchUploadedFiles',
			
			FETCH_BULK_PAYMENT_RECORD_ON_GOING_PAYMENTS:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentRecord/fetchOnGoingPayments',
			FETCH_BULK_PAYMENT_RECORD_HISTORY:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentRecord/fetchHistory',
			REVIEW_BULK_PAYMENT_RECORD:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentRecord/review',

			ADD_BULK_PAYMENT_ITEM:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentItem/addBulkItem',	
			EDIT_BULK_PAYMENT_ITEM:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentItem/editBulkItem',
			FETCH_BULK_PAYMENT_ITEMS:
				'/services/data/v1/BulkPaymentObjects/operations/BulkPaymentItem/fetchBulkItems'		
		}
	},

	SETTINGS: {
		GET_USER_INFOS:
			'/services/data/v1/ExternalUserManagement/objects/ExternalUsers',
		GET_USER_PROFILE_IMAGE:
			'/services/data/v1/Login/operations/Users/getUserProfileImage',
		UPDATE_USER_PROFILE_IMAGE:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/updateUserProfileImage',
		DELETE_USER_PROFILE_IMAGE:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/deleteUserProfileImage',
		UPDATE_USER_PASSWORD:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/updateUserPassword',
		GET_SECURITY_QUESTIONS:
			'/services/data/v1/ExternalUserManagement/operations/SecurityQuestions/getSecurityQuestions',
		VERIFY_UPDATE_SECURITY_QUESTIONS_OTP:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/verifyUpdateSecurityQuestionsOTP',
		UPDATE_SECURITY_QUESTIONS:
			'/services/data/v1/ExternalUserManagement/operations/SecurityQuestions/updateSecurityQuestions',
		REQUEST_UPDATE_SECURITY_QUESTIONS_OTP:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/requestUpdateSecurityQuestionsOTP',
		USERS: '/services/data/v1/Login/objects/Users',
		UPDATE_USER_STATUS:
			'/services/data/v1/ExternalUserManagement/operations/ExternalUsers_2/updateUserStatus',
	},

	CAMPAIGNS: {
		GET_PRE_LOGIN_CAMPAIGNS:
			'/services/data/v1/CampaignEngine/operations/Campaigns/getPreLoginCampaigns',
	},
};
