import { NgFor } from '@angular/common';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd, RouterModule, Event } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TransferPermissions } from '../../../../shared/permissions/transfer.permissions';
import { BeneficiaryPermissions } from '../../../../shared/permissions/beneficiary.permissions';
import { filter, Subscription } from 'rxjs';
import { MENU_DATA } from '../../../layouts/dashboard-layout/menu-data';
import { User } from '../../../../core/authentication/domain/entities/user.entity';

@Component({
	selector: 'app-action-card',
	standalone: true,
	imports: [RouterModule, NgFor, TranslateModule],
	templateUrl: './action-card.component.html'
})
export class ActionCardComponent implements OnInit, OnDestroy {
	@Input() user: User | null = null;
	filteredItems: any[] = [];
	private readonly routerSubscription: Subscription;

	constructor(
		private readonly router: Router,
		private readonly transferPermissions: TransferPermissions,
		private readonly beneficiaryPermissions: BeneficiaryPermissions
	) {
		this.routerSubscription = this.router.events.pipe(
			filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
				).subscribe((event: NavigationEnd) => {
					this.updateMenuItems(event.url);
		});
	}

	ngOnInit(): void {
		// Initialiser avec l'URL actuelle
		this.updateMenuItems(this.router.url);
	}

	ngOnDestroy(): void {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
	}

	private updateMenuItems(currentUrl: string): void {
		// Trouver les données de menu correspondantes à l'URL actuelle
		const menuData = MENU_DATA.find(data => currentUrl.startsWith(data.url));
		
		if (menuData) {
			this.filteredItems = this.filterItemsByPermission(menuData.menuItems);
			this.filteredItems = this.filteredItems.filter(item => item.link !== currentUrl);
		} else {
			this.filteredItems = [];
		}
	}

	private filterItemsByPermission(items: any[]): any[] {
		return items.filter((item) => {
			if (!item.permission) return true;
			
			switch (item.permission) {
				case 'canCreateTransfer':
					return this.transferPermissions.canCreateTransfer;
				case 'canApproveTransfer':
					return this.transferPermissions.canApproveTransfer;
				case 'canRequestTransfer':
					return this.transferPermissions.canCreateTransfer && this.user?.isBusinessUser;

				case 'canCreatePayee':
					return this.beneficiaryPermissions.canCreatePayee;
				case 'canApprovePayee':
					return this.beneficiaryPermissions.canApprovePayee;
				case 'canRequestPayee':
					return this.beneficiaryPermissions.canCreatePayee && this.user?.isBusinessUser;
				default:
					return true;
			}
		});
	}
}