import { BulkPaymentRecord } from "../../../domain/entities/mass-transfer/bulk-payment-record.entity";
import { BulkPaymentRecordDTO } from "../../dtos/mass-transfer/bulk-payment-record.dto";

export class BulkPaymentRecordMapper {
	static toDomain(request: BulkPaymentRecordDTO): BulkPaymentRecord {
		return new BulkPaymentRecord(
			request.recordId,
			request.paymentId,
			request.paymentDate,
			request.scheduledDate,
			request.status,
			request.fromAccount,
			request.totalAmount,
			request.totalTransactions,
			request.companyId,
			request.currency,
			request.batchMode,
			request.rejectionreason,
			request.bulkErrorDetails
		)
	}
}
