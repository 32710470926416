import { DateDTO } from '../../../core-banking/application/dtos/transfer/transfer.dto';
import { BankDate } from '../../domain/entities/bank-date.entity';

export class BankDateMapper {
	static toDomain(date: DateDTO): BankDate {
		return new BankDate(
			date.companyId ?? '',
			date.lastWorkingDate ?? '',
			date.nextWorkingDate ?? '',
			date.currentWorkingDate ?? ''
		);
	}
}
