import { map, Observable } from "rxjs";
import { IMassTransferRepository } from "../../domain/repositories/mass-transfer.repository";
import {
    PaginationRequestDTO,
    UploadFileRequestDTO,
} from "../../application/dtos/mass-transfer/uploaded-file.dto";
import { UploadedFile } from "../../domain/entities/mass-transfer/uploaded-file.entity";
import { inject } from "@angular/core";
import { MassTransferService } from "../../../core-banking/application/services/transfer/mass-transfer.service";
import { BulkPaymentRecord } from "../../domain/entities/mass-transfer/bulk-payment-record.entity";
import { BulkPaymentRecordMapper } from "../../application/mappers/mass-transfer/bulk-payment-record.mapper";

export class MassTransferRepositoryImpl implements IMassTransferRepository {
    private readonly massTransferService = inject(MassTransferService);

    uploadFile(
        request: UploadFileRequestDTO
    ): Observable<{ confirmationNumber: string; sysGeneratedFileName: string }> {
        return this.massTransferService
            .uploadFile(request)
            .pipe(
                map((response) => ({
                    confirmationNumber: response.confirmationNumber,
                    sysGeneratedFileName: response.sysGeneratedFileName,
                }))
            );
    }

    fetchUploadedFiles(
        request: PaginationRequestDTO
    ): Observable<UploadedFile[]> {
        return this.massTransferService
            .fetchUploadedFiles(request)
            .pipe(map((response) => response.uploadedFiles));
    }


    fetchBulkPaymentRecordOnGoingPayments(request: PaginationRequestDTO): Observable<BulkPaymentRecord[]> {
        return this.massTransferService
            .fetchBulkPaymentRecordOnGoingPayments(request)
            .pipe(
                map((response) => response.onGoingPayments
                    .map((record) =>
                        BulkPaymentRecordMapper.toDomain(record)
                    )
                )
            )
    }

    fetchBulkPaymentRecordHistory(request: PaginationRequestDTO): Observable<BulkPaymentRecord[]> {
        return this.massTransferService
            .fetchBulkPaymentRecordHistory(request)
            .pipe(
                map((response) => response.onGoingPayments
                    .map((record) =>
                        BulkPaymentRecordMapper.toDomain(record)
                    )
                )
            )
    }

    reviewBulkPaymentRecord(recordId: string): Observable<{ paymentId: string; status: string; messageDetails: string; }> {
        return this.massTransferService
            .reviewBulkPaymentRecord(recordId)
            .pipe(map((response) => response))
    }
}
