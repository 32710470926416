import { Inject, Injectable } from '@angular/core';
import { IDiscussionRepository } from '../../domain/repositories/discussion.repository';
import { Observable } from 'rxjs';
import {
	CreateAttachmentRequestDTO
} from '../dtos/discussion.dto';

@Injectable({
	providedIn: 'root',
})
export class CreateAttachmentUseCase {
	constructor(
		@Inject('IDiscussionRepository')
		private readonly discussionRepository: IDiscussionRepository
	) {}

	execute(request: CreateAttachmentRequestDTO): Observable<string> {
		return this.discussionRepository.createAttachment(request);
	}
}
