import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthState } from '../../core/authentication/state/reducers/auth.reducer';
import { AccountState } from '../../core/account-management/state/account.reducer';
import { NotificationState } from '../../core/notification/state/reducers/notification.reducer';
import { TransactionState } from '../../core/transaction-management/state/transaction.reducers';
import { SubmissionStatus, TransferState } from '../../core/transfer-management/state/reducers/transfer.reducers';
import { CampaignState } from '../../core/campaign-management/state/campaign.reducers';
import { SettingsState } from '../../core/settings-management/state/settings.reducers';

interface AppState {
    auth: AuthState;
    account: AccountState;
    notification: NotificationState;
    transaction: TransactionState;
    transfer: TransferState;
    campaign: CampaignState;
    settings: SettingsState;
}

// Define initial states (if needed)
const initialAuthState: AuthState = {
    authSession: null,
    contracts: null,
    user: null,
    legalEntity: [],
    loading: false,
    error: null,
    isAuthenticated: false,
    passwordForgotten: null,
    resetPassword: null,
    resendOTP: null,
    verifyOTP: null,
    termsAndConditions: null,
    resendActivateCode: null,
    isActivateCodeSend: false,
    isVerified: false,
    verifiedAccount: null,
    user_id: null,
    isLanguageSet: false,
    isAccountLocked: false,
    lockMessage: null,
    isTrackDeviceRegister: null,
    isTermsAlreadySigned: null,
    resendActivate: null,
    isBusiness: null
};
const initialAccountState: AccountState = {
    accounts: [],
    account: [],
    loading: false,
    accountLoading: false,
    error: null,
    file: null,
    generate: { fileId: '', fileName: '' },
    generateAccount: { fileId: '', fileName: '' },
    accountSelected: null,
    isAccountsLoaded: false
};
const initialNotificationState: NotificationState = {
    notifications: { unreadNotificationCount: '' },
    list: [],
    categories: [],
    readNotification: null,
    counts: [],
    alertPreferences: null,
    loading: false,
    error: null,
};
const initialTransactionState: TransactionState = {
    transactions: [],
    searchTransaction: [],
    loading: false,
    error: null,
    transactionSelected: null
};
const initialCampaignState: CampaignState = {
    preloginCampaigns: [],
    postLoginCampaigns: [],
    loading: false,
    error: null,
};

// Définition de l'état initial pour TransferState
const initialTransferState: TransferState = {
    transfers: [],
    transferSelected: null,
    loading: false,
    error: null,
    bankDate: [],
    uemoaBanks: [],
    transfersHistory: [],
    transferInstructions: [],
    submissionStatus: {} as SubmissionStatus,
    response: null,
    transferDeleted: null,
    baseCurrency: null,
    currencyList: null,
    currencyRates: null,
    fileId: null,
    fileName: null,
};

export const initialSettingState: SettingsState = {
    userProfileImage: '',
    userInfos: null,
    user: null,
    update: null,
    securityQuestions: null,
    mfaAttributes: null,
    isOtpVerified: 'false',
    isSecurityQuestionsUpdated: false,
    isPassordUpdated: false,
    success: '',
    loading: false,
    error: null,
};


export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: ['auth', 'account', 'notification', 'transaction', 'transfer', 'campaign', 'settings'],
        rehydrate: true,
        removeOnUndefined: true,
        storageKeySerializer: (key) => `myApp_${key}`,
    })(reducer);
}

export function clearStateOnLogout(
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
    return function (state, action) {
        if (action.type === '[Auth] Logout') {
            // Clear localStorage keys
            localStorage.removeItem('myApp_auth');
            localStorage.removeItem('myApp_account');
            localStorage.removeItem('myApp_notification');
            localStorage.removeItem('myApp_transaction');
            localStorage.removeItem('myApp_transfer');
            localStorage.removeItem('myApp_campaign');
            localStorage.removeItem('myApp_settings');

            // Reset each slice of the state to its initial state
            return {
                auth: initialAuthState,
                account: initialAccountState,
                notification: initialNotificationState,
                transaction: initialTransactionState,
                transfer: initialTransferState,
                campaign: initialCampaignState,
                settings: initialSettingState
            };
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = [
    clearStateOnLogout,
    localStorageSyncReducer,
];