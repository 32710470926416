import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UploadedFileUseCase } from '../../application/use-cases/mass-transfer/upload-file.use-case';
import { FetchUploadedFilesUseCase } from '../../application/use-cases/mass-transfer/fetch-uploaded-files.use-case';
import { Injectable } from '@angular/core';
import { MassTransferActions } from '../actions/mass-transfer.actions';
import { FetchBulkPaymentRecordOnGoingPaymentsUseCase } from '../../application/use-cases/mass-transfer/fetch-on-going-bulk-payment-record.use-case.ts';
import { FetchBulkPaymentRecordHistorydUseCase } from '../../application/use-cases/mass-transfer/fetch-bulk-payment-record-history.use-case';
import { ReviewBulkPaymentRecordResponseDTO } from '../../../core-banking/application/dtos/transfer/mass-transfer.dto';
import { ReviewBulkPaymentRecordUseCase } from '../../application/use-cases/mass-transfer/review-bulk-payment-record.use-case';

@Injectable()
export class MassTransferEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly uploadFileUseCase: UploadedFileUseCase,
		private readonly fetchUploadedFileUseCase: FetchUploadedFilesUseCase,
		private readonly fetchBulkPaymentRecordUseCase: FetchBulkPaymentRecordOnGoingPaymentsUseCase,
		private readonly fetchBulkPaymentRecordUseCaseHistory: FetchBulkPaymentRecordHistorydUseCase,
		private readonly reviewBulkPaymentRecordUseCase: ReviewBulkPaymentRecordUseCase
	) {}

	uploadFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MassTransferActions.uploadFile),
			switchMap(({ request }) =>
				this.uploadFileUseCase.execute(request).pipe(
					map((response) => MassTransferActions.uploadFileSuccess({ response })),
					catchError((error) =>
						of(MassTransferActions.uploadFileFailure({ error }))
					)
				)
			)
		)
	);

	fetchUploadedFiles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MassTransferActions.fetchUploadedFiles),
			switchMap(({ request }) =>
				this.fetchUploadedFileUseCase.execute(request).pipe(
					map((response) => MassTransferActions.fetchUploadedFilesSuccess({ response })),
					catchError((error) =>
						of(MassTransferActions.fetchUploadedFilesFailure({ error }))
					)
				)
			)
		)
	);

	fetchOnGoingPayment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MassTransferActions.fetchOnGoingPayment),
			switchMap(({ request }) =>
				this.fetchBulkPaymentRecordUseCase.execute(request).pipe(
					map((response) => MassTransferActions.fetchOnGoingPaymentSuccess({ response })),
					catchError((error) =>
						of(MassTransferActions.fetchOnGoingPaymentFailure({ error }))
					)
				)
			)
		)
	);

	fetchHistoryPayment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MassTransferActions.fetchPaymentHistory),
			switchMap(({ request }) =>
				this.fetchBulkPaymentRecordUseCaseHistory.execute(request).pipe(
					map((response) => MassTransferActions.fetchPaymentHistorySuccess({ response })),
					catchError((error) =>
						of(MassTransferActions.fetchPaymentHistoryFailure({ error }))
					)
				)
			)
		)
	);

	reviewBulkPaymentRecord$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MassTransferActions.reviewBulkPaymentRecord),
			switchMap(({ recordId }) =>
				this.reviewBulkPaymentRecordUseCase.execute(recordId).pipe(
					map((response) => MassTransferActions.reviewBulkPaymentRecordSuccess({ response })),
					catchError((error) =>
						of(MassTransferActions.reviewBulkPaymentRecordFailure({ error }))
					)
				)
			)
		)
	);
}
