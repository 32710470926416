export class BulkPaymentRecord {
    constructor(
        public recordId: string,
        public paymentId: string,
        public paymentDate: string,
        public scheduledDate: string,
        public status: string,
        public fromAccount: string,
        public totalAmount: string,
        public totalTransactions: string,
        public companyId: string,
        public currency: string,
        public batchMode: string,
        public rejectionreason: string,
        public bulkErrorDetails: any[],
    ) {}
}