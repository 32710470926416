import { Injectable } from "@angular/core";
import { IMassTransferService } from "../../../domain/interfaces/transfer/mass-transfer-service.interface";
import { catchError, map, Observable, throwError, timeout } from "rxjs";
import {
    BulkItemDTO,
  FetchBulkPaymentItemsResponseDTO,
  FetchOnGoingsPaymentsResponseDTO,
  FetchUploadedFilesResponseDTO,
  PaginationRequestDTO,
  ReviewBulkPaymentRecordResponseDTO,
  UploadFileRequestDTO,
  UploadFileResponseDTO,
  UpSertBulkItemsResponseDTO,
} from "../../dtos/transfer/mass-transfer.dto";
import { CoreBankingApiService } from "../../../infrastructure/api/core-banking-api.service";
import { ErrorHandlingService } from "../../../infrastructure/services/error-handling.service";
import { CORE_BANKING_API_ENDPOINTS } from "../../../infrastructure/constants/api-endpoints";
import { Logger } from "../../../../../shared/helpers/logger-helper";
import { TransfersException } from "../../../domain/errors/core-banking-error";
import { environment } from "../../../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class MassTransferService implements IMassTransferService {
    constructor(
        private readonly api: CoreBankingApiService,
        private readonly errorHandler: ErrorHandlingService,
        private readonly translate: TranslateService
    ) {}

    uploadFile(request: UploadFileRequestDTO): Observable<UploadFileResponseDTO> {
        return this.api
        .post<UploadFileResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.UPLOAD_FILE,
            request
        )
        .pipe(
            map((response) => {
            Logger.info("MassTransferService - uploadFile response", response);
            if (response.confirmationNumber && response.sysGeneratedFileName) {
                return response;
            }
            this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
            throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
            this.errorHandler.handleError(
                error,
                "UPLOAD_FILE_FAILED",
                "Failed to upload file"
            )
            )
        );
    }

    fetchUploadedFiles(
        request: PaginationRequestDTO
    ): Observable<FetchUploadedFilesResponseDTO> {
        return this.api
        .post<FetchUploadedFilesResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.FETCH_UPLOADED_FILES,
            request
        )
        .pipe(
            map((response) => {
                if (response.uploadedFiles && response.uploadedFiles.length > 0) {
                    return response;
                }
                if (response?.dbpErrCode && response?.dbpErrMsg) {
                    Logger.error("There is an error : ", response);
                    this.handleSpecificErrors(
                        response?.dbpErrCode,
                        response?.dbpErrMsg
                    );
                }
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                error instanceof TransfersException
                    ? throwError(() => error)
                    : this.errorHandler.handleError(
                        error,
                        "FETCH_UPLOADED_FILES_FAILED",
                        "Failed to fetch uploaded files"
                    )  
            )
        );
    }


    fetchBulkPaymentRecordOnGoingPayments(request: PaginationRequestDTO): Observable<FetchOnGoingsPaymentsResponseDTO> {
        return this.api
        .post<FetchOnGoingsPaymentsResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.FETCH_BULK_PAYMENT_RECORD_ON_GOING_PAYMENTS,
            request
        )
        .pipe(
            map((response) => {
                Logger.info("MassTransferService - fetchBulkPaymentRecordOnGoingPayments response", response);
                if (response.onGoingPayments.length > 0) {
                    return response;
                }
                this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    "FETCH_BULK_PAYMENT_RECORD_ON_GOING_PAYMENTS_FAILED", 
                    "Failed to fetch bulk payment record on going payments"
                )
            )
        );
    }

    fetchBulkPaymentRecordHistory(request: PaginationRequestDTO): Observable<FetchOnGoingsPaymentsResponseDTO> {
        return this.api
        .post<FetchOnGoingsPaymentsResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.FETCH_BULK_PAYMENT_RECORD_HISTORY,
            request
        )
        .pipe(
            map((response) => {
                Logger.info("MassTransferService - fetchBulkPaymentRecordHistory response", response);
                if (response.onGoingPayments.length > 0) {
                    return response;
                }
                this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    "FETCH_BULK_PAYMENT_RECORD_HISTORY_FAILED", 
                    "Failed to fetch bulk payment record history"
                )
            )
        );
    }

    reviewBulkPaymentRecord(recordId: string): Observable<ReviewBulkPaymentRecordResponseDTO> {
        return this.api
        .post<ReviewBulkPaymentRecordResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.REVIEW_BULK_PAYMENT_RECORD,
            { recordId }
        )
        .pipe(
            map((response) => {
                Logger.info("MassTransferService - reviewBulkPaymentRecord response", response);
                if (!response.dbpErrCode && !response.dbpErrMsg) {
                    return response;
                }
                if (response?.dbpErrCode && response?.dbpErrMsg) {
                    Logger.error("There is an error : ", response);
                    this.handleSpecificErrors(
                        response?.dbpErrCode,
                        response?.dbpErrMsg
                    );
                }
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                error instanceof TransfersException
                    ? throwError(() => error)
                    : this.errorHandler.handleError(
                        error,
                        "REVIEW_BULK_PAYMENT_RECORD_FAILED", 
                        "Failed to review bulk payment record"
                    )  
            )
        )
    }


    addBulkPaymentItem(request: BulkItemDTO): Observable<UpSertBulkItemsResponseDTO> {
        return this.api
        .post<UpSertBulkItemsResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.ADD_BULK_PAYMENT_ITEM,
            request
        )
        .pipe(
            map((response) => {
                Logger.info("MassTransferService - addBulkPaymentItem response", response);
                if (!response.dbpErrCode && !response.dbpErrMsg) {
                    return response;
                }
                this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    "ADD_BULK_PAYMENT_ITEM_FAILED", 
                    "Failed to add bulk payment item"
                )
            )
        )
    }


    editBulkPaymentItem(request: BulkItemDTO): Observable<UpSertBulkItemsResponseDTO> {
        return this.api
        .post<UpSertBulkItemsResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.EDIT_BULK_PAYMENT_ITEM,
            request
        )
        .pipe(
            timeout(environment.timeout),
            map((response) => {
                Logger.info("MassTransferService - editBulkPaymentItem response", response);
                if (!response.dbpErrCode && !response.dbpErrMsg) {
                    return response;
                }
                this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    "EDIT_BULK_PAYMENT_ITEM_FAILED", 
                    "Failed to edit bulk payment item"
                )
            )
        )
    }


    fetchBulkPaymentItems(request: PaginationRequestDTO): Observable<FetchBulkPaymentItemsResponseDTO> {
        return this.api
        .post<FetchBulkPaymentItemsResponseDTO>(
            CORE_BANKING_API_ENDPOINTS.TRANSFERS.MASS_TRANSFER.FETCH_BULK_PAYMENT_ITEMS,
            request
        )
        .pipe(
            map((response) => {
                Logger.info("MassTransferService - fetchBulkPaymentItems response", response);
                if (response.bulkItems.length > 0) {
                    return response;
                }
                this.handleSpecificErrors(response.dbpErrCode, response.dbpErrMsg);
                throw new Error(response.dbpErrMsg);
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    "FETCH_BULK_PAYMENT_ITEMS_FAILED",  
                    "Failed to fetch bulk payment items"
                )
            )
        )
    }

 
	private handleSpecificErrors(status: string, errorMessage: string): void {

		switch (status) {
			case '21220':
            case '12611':
            case '15001':
            case '21230':
            case '12001':
            case '12600':
				throw new TransfersException(
					errorMessage
				);
			default:
				throw new Error(errorMessage);
		}
	}
}

