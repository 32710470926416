import { Inject, Injectable } from '@angular/core';
import { IMassTransferRepository } from '../../../domain/repositories/mass-transfer.repository';

@Injectable({ providedIn: 'root' })
export class ReviewBulkPaymentRecordUseCase {
    constructor(@Inject('IMassTransferRepository') private readonly massTransferRepository: IMassTransferRepository) { }

    execute(recordId: string) {
        return this.massTransferRepository.reviewBulkPaymentRecord(recordId);
    }
}
