import { SearchTransaction } from '../../domain/entities/search-transaction.entity';
import { SearchTransactionsDTO } from '../dtos/transaction.dto';

export class SearchTransactionMapper {
	static toDomain(transaction: SearchTransactionsDTO): SearchTransaction {
		return new SearchTransaction(
			transaction.transactionId,
			transaction.amount,
			transaction.description,
			transaction.fromAccountBalance,
			transaction.scheduledDate,
			transaction.transactionDate,
			transaction.transactionType,
			transaction.transactionCurrency,
			transaction.valueDateTime,
			transaction?.transactionsNotes,
			transaction?.ISIN,
			transaction?.documentId
		);
	}
}
