<!doctype html>
<html lang="fr">
  <head>
    <title>Maintenance en cours</title>
    <meta charset="utf-8">
    <meta name="robots" content="noindex">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
  </head>
  <body class="flex justify-center items-center h-screen bg-black">
    <div class="container max-w-[90%] lg:max-w-[50%] text-justify">
      <h1 class="sm:text-xl lg:text-3xl text-center font-semibold text-[#ff3c00]">SITE EN MAINTENANCE !</h1>
      <p class="text-white lg:text-lg mt-4">
        Cher(e) Client(e), en raison de travaux d’optimisation, nos services de banque en ligne sont momentanément interrompus du 7 au 9 mars 2025 inclus.
        <br>Pour toute information complémentaire, notre équipe se tient à votre disposition pour vous assister.
        <br>Vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:reclamation@bridgebankgroup.com" class="text-[#ff3c00] hover:underline">reclamation&#64;bridgebankgroup.com</a> ou via WhatsApp au <a href="https://wa.me/2250574861294" class="text-[#ff3c00] hover:underline">+225 05 74 86 12 94</a>.
      </p>
      <p class="mt-4 text-white text-lg">
        <a href="#" class="text-[#ff3c00] hover:underline text-lg">Equipe Bridge Bank Group</a> s’excuse pour les éventuels désagréments.
      </p>
      <div class="illustration flex justify-start mt-6">
        <img src="/assets/images/maintenance.svg" alt="Maintenance en cours" class="max-w-[95%] h-auto">
      </div>
    </div>
  </body>
</html>