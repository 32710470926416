import { NgModule } from '@angular/core';
import { ITransferRepository } from './domain/repositories/transfer.repository';
import { TransferRepositoryImpl } from './infrastructure/repositories/transfer.repository.impl';
import { provideEffects } from '@ngrx/effects';
import { TransferEffects } from './state/effects/transfer.effects';
import { TransferService } from '../core-banking/application/services/transfer/transfer.service';
import { ITransferService } from '../core-banking/domain/interfaces/transfer/transfer-service.interface';
import { CommonModule } from '@angular/common';
import { ApprovalRequestRepositoryImpl } from './infrastructure/repositories/approval-request.repository.impl';
import { ApprovalRequestService } from '../core-banking/application/services/transfer/approval-request.service';
import { MassTransferRepositoryImpl } from './infrastructure/repositories/mass-transfer.repository.impl';
import { MassTransferEffects } from './state/effects/mass-transfer.effects';

@NgModule({
	imports: [CommonModule],
	providers: [
		{ provide: ITransferService, useClass: TransferService },
		{ provide: ITransferRepository, useClass: TransferRepositoryImpl },
		{ provide: 'IApprovalRequestRepository', useClass: ApprovalRequestRepositoryImpl },
		{ provide: 'IApprovalRequestService', useClass: ApprovalRequestService},
		{ provide: 'IMassTransferRepository', useClass: MassTransferRepositoryImpl },
		provideEffects(MassTransferEffects),
		provideEffects(TransferEffects),
	],
})
export class CoreTransferManagementModule {}
