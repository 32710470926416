import { Account } from '../../domain/entities/account.entity';
import { IAccountRepository } from '../../domain/repositories/account.repository';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AccountService } from '../../../core-banking/application/services/account/account.service';
import { map } from 'rxjs/operators';
import { AccountMapper } from '../../application/mappers/account.mapper';
import { AccountDetailsMapper } from '../../application/mappers/account-details.mapper';
import { AccountDetail } from '../../domain/entities/account-details.entity';
import {
	AccountGenerate,
	GenerateAccountStatementRequestDTO,
} from '../../application/dtos/account-details.dto';

export class AccountRepositoryImpl implements IAccountRepository {
	private readonly accountService = inject(AccountService);
	private readonly allowAccountSet = new Set<string>(["RC251", "RC252", "RC202", "RC203", "RC204", "RC253"]);

	getAccounts(): Observable<Account[]> {
		return this.accountService.getAccounts().pipe(
			map((response) => {
			return response.Accounts
				.map(AccountMapper.mapToDomain)
				.filter((account) => {
				const key = account.productName?.slice(0, 5);
				return key !== undefined && this.allowAccountSet.has(key);
				});
			})	
		);
	}
	getDetails(accountID: string): Observable<AccountDetail[]> {
		return this.accountService.getDetails(accountID).pipe(
			map((response) => {
				return response.Accounts.map((account) =>
					AccountDetailsMapper.mapToDomain(account)
				);
			})
		);
	}

	generateReleveRib(
		iban: string
	): Observable<AccountGenerate> {
		return this.accountService.generateReleveRib(iban).pipe(
			map((response) => {
				return response.Account[0];
			})
		);
	}
	generateReleveRibAccount(data: {
		fileId: string;
		fileName: string;
	}): Observable<any> {
		return this.accountService.generateReleveRibAccount(data).pipe(
			map((response) => {
				return response;
			})
		);
	}

	generateAccountStatement(
		data: GenerateAccountStatementRequestDTO
	): Observable<AccountGenerate> {
		return this.accountService.generateAccountStatement(data).pipe(
			map((response) => {
				return response.Account[0];
			})
		);
	}
}
