import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Transaction } from '../domain/entities/transaction.entity';
import {
	TransactionRequestDTO,
	SearchTransactionsRequestDTO,
} from '../application/dtos/transaction.dto';
import { SearchTransaction } from '../domain/entities/search-transaction.entity';

export const TransactionActions = createActionGroup({
	source: 'Transaction',
	events: {
		'Load transactions': emptyProps(),
		'Load scheduled transactions': emptyProps(),
		'Load recent transactions': props<{ request: TransactionRequestDTO }>(),
		'Load transactions Success': props<{ transactions: Transaction[] }>(),
		'Load transactions Failure': props<{ error: any }>(),
		'Select transaction by id': props<{ id: string }>(),
		'Select transaction by id Success': props<{ transaction: Transaction }>(),
		'Select transaction by id Failure': props<{ error: any }>(),
		'Search transactions': props<{
			request: SearchTransactionsRequestDTO;
		}>(),
		'Search transactions Success': props<{
			searchTransaction: SearchTransaction[];
		}>(),
		'Search transactions Failure': props<{ error: any }>(),
	},
});
