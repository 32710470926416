export class AccountDetail {
	constructor(
		public accountID: string,
		public Account_id: string,
		public accountHolder: string,
		public accountName: string,
		public arrangementId: string,
		public accountType: string,
		public displayName: string,
		public availableBalance: string,
		public currencyCode: string,
		public currentBalance: string,
		public dividendLastPaidAmount: string,
		public dividendPaidYTD: string,
		public dividendRate: string,
		public lastPaymentDate: string,
		public nickName: string,
		public openingDate: string,
		public outstandingBalance: string,
		public paymentDue: string,
		public payoffAmount: string,
		public pendingDeposit: string,
		public pendingWithdrawal: string,
		public principalBalance: string,
		public routingNumber: string,
		public supportBillPay: string,
		public supportDeposit: string,
		public supportTransferFrom: string,
		public supportTransferTo: string,
		public swiftCode: string,
		public totalCreditMonths: string,
		public totalDebitsMonth: string,
		public transferLimit: string,
		public supportChecks: string,
		public IBAN: string,
		public AccountName: string,
		public blockedAmount: string,
		public productId: string,
		public approvedOverdraftLimit: string,
		public maturityDate: string,
		public interestRate: string,
		public interestEarned: string,
		public arrDormancyStatus: string,
		public productName: string,
		public sanctionedAmount: string,
		public sanctionedDate: string,
		public paidInstallmentsCount: string,
		public futureInstallmentsCount: string,
		public overDueInstallmentsCount: string,
		public lastPaymentAmount: string,
		public nextPaymentAmount: string,
		public productDescription: string
	) {}
}
