import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { MENU_DATA } from './menu-data';
import { CarouselComponent } from '../../shared/components/carousel/carousel.component';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';
import { ActionCardComponent } from '../../shared/components/action-card/action-card.component';
import { User } from '../../../core/authentication/domain/entities/user.entity';
import { Store } from '@ngrx/store';
import { selectUser } from '../../../core/authentication/state/selectors/auth.selectors';

@Component({
	selector: 'app-dashboard-layout',
	standalone: true,
	imports: [
		HeaderComponent,
		RouterOutlet,
		FooterComponent,
		ActionCardComponent,
		BreadcrumbComponent,
		SidebarComponent,
		CarouselComponent
	],
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.css'],
})
export class DashboardLayoutComponent implements OnInit {
	sidebarVisible: boolean = false;
	account: any = '';
	user: User | null = null;

	menuItems: Array<{ title: string; link: string }> = [];
	breadcrumbItems: Array<{ title: string; link: string }> = [];


	constructor(
		private readonly store: Store,
		private readonly router: Router
	) {}

	ngOnInit(): void {
		this.router.events.subscribe(() => {
			this.updateMenuAndBreadcrumb();
		});

		this.updateMenuAndBreadcrumb(); // Initial load

		this.store.select(selectUser).subscribe((user) => {
			if (user) {
				this.user = user;
			}
		})
	}

	toggleSidebar() {
		this.sidebarVisible = !this.sidebarVisible;
	}

	closeSidebar() {
		this.sidebarVisible = false;
	}

	handleClickOutside() {
		this.sidebarVisible = false;
	}

	updateMenuAndBreadcrumb(): void {
		const currentUrl = this.router.url.split('?')[0];

		const matchingData = MENU_DATA.find((data) =>
			currentUrl.endsWith(data.url)
		);

		if (matchingData) {
			if (matchingData.menuItems) {
				this.menuItems = matchingData.menuItems;
			}
			if (matchingData.breadcrumbItems) {
				this.breadcrumbItems = matchingData.breadcrumbItems;
			}
		} else {
			// Default fallback
			this.menuItems = [];
			this.breadcrumbItems = [];
		}
	}
}
