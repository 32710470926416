import { Component } from '@angular/core';

@Component({
  selector: 'app-page-in-construction',
  standalone: true,
  imports: [],
  templateUrl: './page-in-construction.component.html'
})
export class PageInConstructionComponent {

}
