import { Inject, Injectable } from '@angular/core';
import { IMassTransferRepository } from '../../../domain/repositories/mass-transfer.repository';
import { UploadFileRequestDTO } from '../../dtos/mass-transfer/uploaded-file.dto';

@Injectable({ providedIn: 'root' })
export class UploadedFileUseCase {
    constructor(@Inject('IMassTransferRepository') private readonly massTransferRepository: IMassTransferRepository) { }

    execute(request: UploadFileRequestDTO) {
        return this.massTransferRepository.uploadFile(request);
    }
}
