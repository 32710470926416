


export const MENU_DATA = [
	// Beneficiary Section
	{
		url: '/beneficiary',
		menuItems: [
			{
				title: 'MENU_ITEMS.CREATE_A_BENEFICIARY',
				link: '/beneficiary/new',
				permission: 'canCreatePayee'
			},
			{
				title: 'MENU_ITEMS.LIST_OF_BENEFICIARIES',
				link: '/beneficiary',
			},
			{
				title: 'MENU_ITEMS.MY_REQUEST',
				link: '/beneficiary/request',
				permission: 'canRequestPayee'
			},
			{
				title: 'MENU_ITEMS.MY_REQUEST_HISTORY',
				link: '/beneficiary/request/history',
				permission: 'canRequestPayee'
			},
			{
				title: 'MENU_ITEMS.MY_APPROVAL',
				link: '/beneficiary/approval',
				permission: 'canApprovePayee'
			},
			{
				title: 'MENU_ITEMS.MY_APPROVAL_HISTORY',
				link: '/beneficiary/approval/history',
				permission: 'canApprovePayee'
			},
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
		],
	},
	{
		url: '/beneficiary/new',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{ title: 'BREADCRUMB_ITEMS.CREATE_A_BENEFICIARY', link: '/' },
		],
	},
	{
		url: '/beneficiary/request',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{ title: 'MENU_ITEMS.MY_REQUEST', link: '' },
		],
	},
	{
		url: '/beneficiary/request/history',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{ title: 'MENU_ITEMS.MY_REQUEST_HISTORY', link: '' },
		],
	},
	{
		url: '/beneficiary/approval',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{ title: 'MENU_ITEMS.MY_APPROVAL', link: '' },
		],
	},
	{
		url: '/beneficiary/approval/history',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.BENEFICIARY_MANAGEMENT',
				link: '/beneficiary',
			},
			{ title: 'MENU_ITEMS.MY_APPROVAL_HISTORY', link: '' },
		],
	},


	// Transfer Section
	{
		url: '/transfer',
		menuItems: [
			{ title: 'MENU_ITEMS.MAKE_A_TRANSFER', link: '/transfer', permission: 'canCreateTransfer' },
			{ title: 'MENU_ITEMS.TRANSFER_HISTORY', link: '/transfer/history' },
			{ title: 'MENU_ITEMS.PLANNED_TRANSFER', link: '/transfer/planned' },
			{ title: 'MENU_ITEMS.MY_REQUEST', link: '/transfer/request', permission: 'canRequestTransfer' },
			{ title: 'MENU_ITEMS.MY_REQUEST_HISTORY', link: '/transfer/request/history', permission: 'canRequestTransfer' },
			{ title: 'MENU_ITEMS.MY_APPROVAL', link: '/transfer/approval', permission: 'canApproveTransfer' },
			{ title: 'MENU_ITEMS.MY_APPROVAL_HISTORY', link: '/transfer/approval/history', permission: 'canApproveTransfer' },
			{ title: 'MENU_ITEMS.MASS_TRANSFER', link: '/transfer/mass', permission: 'canApproveTransfr' },
			{ title: 'MENU_ITEMS.CREATE_MASS_TRANSFER', link: '/transfer/mass/new', permission: 'canApproveTransfr' },
			{ title: 'MENU_ITEMS.MASS_TRANSFER_FILE_EXAMPLE', link: '/transfer/mass/file-example', permission: 'canApproveTransfer1' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '' },
		],
	},
	{
		url: '/transfer/history',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{
				title: 'BREADCRUMB_ITEMS.TRANSFERS',
				link: '/transfer',
			},
			{
				title: 'BREADCRUMB_ITEMS.TRANSFER_HISTORY',
				link: '',
			},
		],
	},
	{
		url: '/transfer/planned',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.SCHEDULED_TRANSFERS', link: '/beneficiary' }
		],
	},
	{
		url: '/transfer/internal',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.INTERNAL_TRANSFERS', link: '/' },
		],
	},
	{
		url: '/transfer/external/sica',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.SICA_TRANSFER', link: '/' }
		],
	},
	{
		url: '/transfer/external/rtgs',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.RTGS_TRANSFER', link: '/' }
		],
	},
	{
		url: '/transfer/international',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'MENU_ITEMS.INTERNATIONAL_TRANSFERS', link: '/' },
		],
	},
	{
		url: '/transfer/request',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFER_REQUEST', link: '' },
			{ title: 'BREADCRUMB_ITEMS.REQUEST_LIST', link: '' },
		],
	},
	{
		url: '/transfer/request/history',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFER_REQUEST', link: '.' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFER_HISTORY', link: '/transfer/request/history' },
		],
	},
	{
		url: '/transfer/approval',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.APPROVAL', link: '#' },
			{ title: 'BREADCRUMB_ITEMS.APPROVAL_LIST', link: '#' },
		],
	},
	{
		url: '/transfer/approval/history',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.APPROVAL', link: '#' },
			{ title: 'BREADCRUMB_ITEMS.APPROVAL_HISTORY', link: '#' },
		],
	},
	{
		url: '/transfer/mass',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.MASS_TRANSFERS', link: '/transfer/mass' },
			{ title: 'MENU_ITEMS.MASS_TRANSFER', link: '#' },
		],
	},
	{
		url: '/transfer/mass/new',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.MASS_TRANSFERS', link: '/transfer/mass' },
			{ title: 'BUTTONS.NEW', link: '#' },
		],
	},
	{
		url: '/transfer/mass/file-example',
		menuItems: [], 
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSFERS', link: '/transfer' },
			{ title: 'BREADCRUMB_ITEMS.MASS_TRANSFERS', link: '/transfer/mass' },
			{ title: 'BREADCRUMB_ITEMS.EXAMPLE_FILE', link: '#' },
		],
	},


	// Account Section
	{
		url: '/account',
		menuItems: [
			{ title: 'BREADCRUMB_ITEMS.ACCOUNT', link: '/account' },
			{ title: 'BREADCRUMB_ITEMS.SEARCH_TRANSACTIONS', link: '/account/transactions/search' },
			{
				title: 'MENU_ITEMS.MAKE_A_TRANSFER',
				link: '/transfer',
				permission: 'canCreateTransfer',
			},
			{ title: 'BREADCRUMB_ITEMS.ACCOUNT_STATEMENT', link: '/account/statement' },
			{ title: 'BREADCRUMB_ITEMS.RIB', link: '/account/rib' }
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.ACCOUNT', link: '/account' },
		],
	},
	{
		url: '/account/rib',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.RIB', link: '/account/rib' },
		],
	},
	{
		url: '/account/statement',
		menuItems: [],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.ACCOUNT_STATEMENT', link: '/account/statement' },
		],
	},
	{
		url: '/account/transactions/search',
		menuItems: [
			{ title: 'RIB', link: '/account/rib' },
			{ title: 'MENU_ITEMS.MY_ACCOUNTS', link: '/' },
		],
		breadcrumbItems: [
			{ title: 'BREADCRUMB_ITEMS.HOME', link: '/' },
			{ title: 'BREADCRUMB_ITEMS.TRANSACTIONS_HISTORY', link: '/account/transactions/search' },
		]
	},
	
	// FAQ Section
	{
		url: '/faq',
		menuItems: [
			{ title: 'MENU_ITEMS.CONTACT_US', link: '/contact-us' },
			{ title: 'MENU_ITEMS.FIND_US', link: '/find-us' },
		],
	},
];
