import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import {
	BankDateDTO,
	RtgsTransferDTO,
	InternationalTransferRequestDTO,
	RtgsTransferRequestDTO,
	RtgsTransferResponseDTO,
	SikaTransferRequestDTO,
	TransferDTO,
	TransferRequestDTO,
	TransferResponseDTO,
	TransferStandingOrderDTO,
	TransferStandingOrderRequestDTO,
	TransferStandingOrderResponseDTO,
	TransferSicaStandingOrderRequestDTO,
	TransferInternationalStandingOrderRequestDTO,
	TransferHistoryResponseDTO,
	TransferHistoryDTO,
	TransferInstructionsResponseDTO,
	TransferInstructionsDTO,
	TransferDeleteRequestDTO,
	TransferDeleteResponseDTO,
	BaseCurrencyRequestDTO,
	BaseCurrencyResponseDTO,
	DashboardCurrencyListRequestDTO,
	DashboardCurrencyListResponseDTO,
	CurrencyRatesRequestDTO,
	CurrencyRatesResponseDTO,
	GetTransferAdviceRequestDTO,
	GetTransferAdviceResponseDTO,
} from '../../dtos/transfer/transfer.dto';
import { Logger } from '../../../../../shared/helpers/logger-helper';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { ITransferService } from '../../../domain/interfaces/transfer/transfer-service.interface';
import { UemoaBanksResponseDto } from '../../dtos/transfer/uemoa-bank.dto';
import {
	InvalidParametersException,
	TransfersException,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class TransferService implements ITransferService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly cookieService: CookieService,
		private readonly translate: TranslateService
	) {}

	createOwnAccountTransfer(
		request: TransferRequestDTO
	): Observable<TransferDTO> {
		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';

		return this.api
			.createOwnAccountTransfer<TransferResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.OWN_ACCOUNT_TRANSFER,
				currentLegalEntityId,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createOwnAccountTransfer response', response);
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							response?.httpStatusCode ?? transferDetails?.dbpErrCode,
							response?.errmsg ?? transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_OWN_ACCOUNT_TRANSFER_FAILED',
								'Failed to create own account transfer'
						  )
				)
			);
	}

	createIntraBankTransfer(
		request: TransferRequestDTO
	): Observable<TransferDTO> {
		return this.api
			.post<TransferResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.INTRA_BANK_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createIntraBankTransfer response', response);
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_INTRA_BANK_TRANSFER_FAILED',
								'Failed to create intra-bank transfer'
						  )
				)
			);
	}

	createSikaTransfer(
		request: SikaTransferRequestDTO
	): Observable<TransferDTO> {
		Logger.info('createSikaTransfer Payload :', request);
		return this.api
			.post<TransferResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.SIKA_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createSikaTransfer response', response);
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_SIKA_TRANSFER_FAILED',
								'Failed to create sika transfer'
						  )
				)
			);
	}

	createRtgsTransfer(
		request: RtgsTransferRequestDTO
	): Observable<RtgsTransferDTO> {
		console.log('request: ', request);

		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';
		return this.api
			.createOwnAccountTransfer<RtgsTransferResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.RTGS_TRANSFER,
				currentLegalEntityId,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createRtgsTransfer response', response);
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						Logger.error('transferDetails', transferDetails);
						Logger.error('dbpErrMsg', transferDetails?.dbpErrMsg);
						Logger.error('dbpErrCode', transferDetails?.dbpErrCode);
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							response?.errmsg ?? transferDetails?.dbpErrMsg
						);
					}
					Logger.error('errorMessage', errorMessage);
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_RTGS_TRANSFER_FAILED',
								'Failed to create rtgs transfer'
						  )
				)
			);
	}

	createInternationalTransfer(
		request: InternationalTransferRequestDTO
	): Observable<TransferDTO> {
		return this.api
			.post<TransferResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.INTERNATIONAL_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createInternationalTransfer response', response);
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.httpStatusCode ??
								transferDetails?.dbpErrCode ??
								transferDetails.opstatus,
							response?.errmsg ?? transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_INTERNATIONAL_TRANSFER_FAILED',
								'Failed to create international transfer'
						  )
				)
			);
	}

	getUemoaBanks(): Observable<UemoaBanksResponseDto> {
		return this.api
			.get<UemoaBanksResponseDto>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_UEMOA_BANKS
			)
			.pipe(
				map((response) => {
					Logger.info('getUemoaBanks response', response);
					return response;
				}),
				catchError((error) => {
					return this.errorHandler.handleError(
						error,
						'GET_UEMOA_BANKS_FAILED',
						'Failed to get UEMOA banks'
					);
				})
			);
	}
	getBankDate(): Observable<BankDateDTO> {
		return this.api
			.post<BankDateDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_BANK_DATE,
				{}
			)
			.pipe(
				map((response) => {
					Logger.info('getBankDate response', response);
					return response;
				}),
				catchError((error) => {
					return this.errorHandler.handleError(
						error,
						'GET_BANK_DATE_FAILED',
						'Failed to get bank date'
					);
				})
			);
	}

	createOwnAccountStandingOrder(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO> {
		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';

		return this.api
			.createOwnAccountTransfer<TransferStandingOrderResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.OWN_ACCOUNT_STANDING_ORDER_TRANSFER,
				currentLegalEntityId,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('createOwnAccountStandingOrder response', response);
					const transferDetails = response?.StandingOrder?.[0];
					if (
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							response?.httpStatusCode ?? transferDetails?.dbpErrCode,
							response?.errmsg ?? transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_OWN_ACCOUNT_STANDING_ORDER_TRANSFER_FAILED',
								'Failed to create own account transfer'
						  )
				)
			);
	}

	createIntraBankStandingOrderTransfer(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO> {
		return this.api
			.post<TransferStandingOrderResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.INTER_BANK_STANDING_ORDER_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info(
						'createIntraBankStandingOrderTransfer response',
						response
					);
					const transferDetails = response?.StandingOrder?.[0];
					if (
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_INTRA_BANK_STANDING_ORDER_TRANSFER_FAILED',
								'Failed to create intra-bank standing order transfer'
						  )
				)
			);
	}

	createSicaStandingOrderTransfer(
		request: TransferSicaStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO> {
		return this.api
			.post<TransferStandingOrderResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.SIKA_STANDING_ORDER_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info(
						'createIntraBankStandingOrderTransfer response',
						response
					);
					const transferDetails = response?.StandingOrder?.[0];
					if (
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_INTRA_BANK_STANDING_ORDER_TRANSFER_FAILED',
								'Failed to create intra-bank standing order transfer'
						  )
				)
			);
	}

	createInternationalStandingOrder(
		request: TransferInternationalStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO> {
		return this.api
			.post<TransferStandingOrderResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.INTERNATIONAL_STANDING_ORDER_TRANSFER,
				request
			)
			.pipe(
				map((response) => {
					Logger.info(
						'createIntraBankStandingOrderTransfer response',
						response
					);
					const transferDetails = response?.StandingOrder?.[0];
					if (
						transferDetails?.status === 'Executed' ||
						transferDetails?.status === 'success' ||
						transferDetails?.status === 'Pending'
					) {
						return transferDetails;
					}
					const errorMessage =
						transferDetails?.dbpErrMsg ?? response?.errmsg;
					if (transferDetails?.dbpErrCode && transferDetails?.dbpErrMsg) {
						this.handleSpecificErrors(
							transferDetails?.dbpErrCode,
							transferDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'CREATE_INTERNATIONAL_STANDING_ORDER_TRANSFER_FAILED',
								'Failed to create international standing order transfer'
						  )
				)
			);
	}

	getTransferHistory(): Observable<TransferHistoryDTO[]> {
		return this.api
			.post<TransferHistoryResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_TRANSFER_HISTORY
			)
			.pipe(
				map((response) => {
					if (response?.dbpErrCode && response?.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					const transferDetails = response?.OneTimeTransfer?.[0];
					if (transferDetails?.transfers) {
						return transferDetails.transfers;
					}

					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'get transfer history'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'GET_TRANSFER_HISTORY_FAILED',
								'Failed to get transfer history'
						  )
				)
			);
	}

	getTransferAdvice(request: GetTransferAdviceRequestDTO): Observable<{ fileId: string; fileName: string; }> {
		return this.api
			.post<GetTransferAdviceResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GENERATE_TRANSFER_ADVICE,
				request
			)
			.pipe(
				map((response) => {
					if (response?.dbpErrCode && response?.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					if (response.Account) {
						return response.Account[0];
					}
					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'get transfer advice'
					);
					
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
							error,
							'GENERATE_TRANSFER_ADVICE_FAILED',
							'Failed to generate transfer advice'
						) 
				)
						
			)
	}

	getInstructions(): Observable<TransferInstructionsDTO[]> {
		return this.api
			.post<TransferInstructionsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_INSTRUCTIONS
			)
			.pipe(
				map((response) => {
					if (response?.dbpErrCode && response?.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					const transferDetails = response?.StandingInstruction;
					if (transferDetails) {
						return transferDetails;
					}

					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'get transfer instructions'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'GET_INSTRUCTIONS_FAILED',
								'Failed to get transfer instructions'
						  )
				)
			);
	}

	transferToOwnAccountsDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.api
			.post<TransferDeleteResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.TRANSFER_TO_OWN_ACCOUNTS_DELETE,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.dbpErrMsg) {
						return response;
					}
					this.handleSpecificErrors(
						response?.dbpErrCode,
						response?.dbpErrMsg
					);
					throw new Error(
						response.dbpErrCode ??
							response.dbpErrMsg ??
							'delete transfer To Own Accounts'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'TRANSFER_TO_OWN_ACCOUNTS_DELETE',
								'Failed to delete transfer To Own Accounts'
						  )
				)
			);
	}

	intraBankAccFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.api
			.post<TransferDeleteResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.INTRA_BANK_ACC_FUND_TRANSFER_DELETE,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.dbpErrMsg) {
						return response;
					}
					this.handleSpecificErrors(
						response?.dbpErrCode,
						response?.dbpErrMsg
					);
					throw new Error(
						response.dbpErrCode ??
							response.dbpErrMsg ??
							'delete intra Bank Acc Fund Transfer'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'INTRA_BANK_ACC_FUND_TRANSFER_DELETE',
								'Failed to delete intra Bank Acc Fund Transfer'
						  )
				)
			);
	}

	interBankFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.api
			.post<TransferDeleteResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.INTER_BANK_FUND_TRANSFER_DELETE,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.dbpErrMsg) {
						return response;
					}
					this.handleSpecificErrors(
						response?.dbpErrCode,
						response?.dbpErrMsg
					);
					throw new Error(
						response.dbpErrCode ??
							response.dbpErrMsg ??
							'delete  intra Bank Fund Transfer'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'INTRA_BANK_FUND_TRANSFER_DELETE',
								'Failed to delete inter Bank Fund Transfer'
						  )
				)
			);
	}

	internationalFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.api
			.post<TransferDeleteResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS
					.INTERNATIONAL_FUND_TRANSFER_DELETE,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.dbpErrMsg) {
						return response;
					}
					this.handleSpecificErrors(
						response?.dbpErrCode,
						response?.dbpErrMsg
					);
					throw new Error(
						response.dbpErrCode ??
							response.dbpErrMsg ??
							'delete international fund transfer'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'INTERNATIONAL_FUND_TRANSFER_DELETE',
								'Failed to delete international fund transfer'
						  )
				)
			);
	}

	fetchBaseCurrency(
		data: BaseCurrencyRequestDTO
	): Observable<BaseCurrencyResponseDTO> {
		return this.api
			.post<BaseCurrencyResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.FETCH_BASE_CURRENCY,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.errmsg) {
						return response;
					}
					if (response.dbpErrCode && response.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'fetch Base Currency'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'FETCH_BASE_CURRENCY',
								'Failed to fetch Base Currency'
						  )
				)
			);
	}
	fetchDashboardCurrencyList(
		data: DashboardCurrencyListRequestDTO
	): Observable<DashboardCurrencyListResponseDTO> {
		return this.api
			.post<DashboardCurrencyListResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.FETCH_DASHBOARD_CURRENCY_LIST,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.errmsg) {
						return response;
					}
					if (response.dbpErrCode && response.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'fetch Dashboard Currency List'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'FETCH_DASHBOARD_CURRENCY_LIST',
								'Failed to fetch Dashboard Currency List'
						  )
				)
			);
	}
	fetchCurrencyRates(
		data: CurrencyRatesRequestDTO
	): Observable<CurrencyRatesResponseDTO> {
		return this.api
			.post<CurrencyRatesResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.FETCH_CURRENCY_RATES,
				data
			)
			.pipe(
				map((response) => {
					if (!response?.dbpErrCode && !response?.errmsg) {
						return response;
					}
					if (response.dbpErrCode && response.errmsg) {
						this.handleSpecificErrors(
							response?.dbpErrCode,
							response?.errmsg
						);
					}
					throw new Error(
						response.dbpErrCode ??
							response.errmsg ??
							'fetch Currency Rates'
					);
				}),
				catchError((error) =>
					error instanceof TransfersException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'FETCH_CURRENCY_RATES',
								'Failed to fetch fetch Currency Rates'
						  )
				)
			);
	}

	private handleSpecificErrors(status: string, errorMessage: string): void {
		Logger.error('dbpErrCode', status);
		Logger.error('dbpErrMsg', errorMessage);
		switch (status) {
			case '12001':
				throw new UnauthorizedException();
			case '12600':
				throw new TransfersException(
					this.translate.instant('TRANSFER.ERRORS.IDENTICAL_TRANSFER')
				);
			case '12307':
				throw new TransfersException(
					this.translate.instant('TRANSFER.ERRORS.IDENTICAL_ACCOUNT')
				);
			case '10624':
				throw new TransfersException(
					this.translate.instant('TRANSFER.ERRORS.INVALID_REQUEST')
				);
			case '12504':
				throw new TransfersException(
					// this.translate.instant('TRANSFER.ERRORS.TRANSACTION_LIMIT_EXCEEDED')
					errorMessage
				);
			case '12000':
			case '8009':
			case '400':
				throw new TransfersException(
					this.translate.instant('TRANSFER.ERRORS.INTERNAL_SERVICE_ERROR')
				);
			case '10742':
			case '12040':
				throw new InvalidParametersException(errorMessage);
			default:
				throw new Error(errorMessage);
		}
	}
}
