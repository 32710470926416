import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountActions } from './account.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GetAccountUseCase } from '../application/use-cases/get-account.use-case';
import { GetAccountDetailsUseCase } from '../application/use-cases/get-account-details.use-case';
import { GenerateReleveRibUseCase } from '../application/use-cases/generate-releve-rib.use-case';
import { GenerateReleveRibAccountUseCase } from '../application/use-cases/generate-releve-rib-account.use-case';
import { GenerateAccountStatementUseCase } from '../application/use-cases/generate-account-statement.use-case';
import { TransactionActions } from '../../transaction-management/state/transaction.actions';
import { TransactionType } from '../../transaction-management/domain/entities/transaction.entity';

@Injectable()
export class AccountEffects {
	loadAccounts$ = createEffect(() => {
		return this.actions$.pipe(
		  ofType(AccountActions.loadAccounts),
		  switchMap(() => {
			return this.getAccountUseCase.execute().pipe(
			  map((response) =>
				AccountActions.loadAccountSuccess({ accounts: response })
			  ),
			  catchError((error) => {
				return of(AccountActions.loadAccountFailure({ error }));
			  })
			);
		  })
		);
	  });
	
	  // Effet pour charger les transactions récentes après le chargement des comptes
	  loadRecentTransactionsAfterAccountsLoaded$ = createEffect(() =>
		this.actions$.pipe(
		  ofType(AccountActions.loadAccountSuccess), // Écouter le succès du chargement des comptes
		  switchMap((action) => {
			const firstAccountId = action.accounts[0]?.Account_id; // Prendre l'ID du premier compte
			if (firstAccountId) {
			  return of(
				TransactionActions.loadRecentTransactions({
				  request: {
					accountID: firstAccountId,
					transactionType: TransactionType.All,
					offset: 0,
					limit: 10,
					isScheduled: "false",
					order: "desc"
					// Ajoutez d'autres paramètres de transactionRequest si nécessaire
				  },
				})
			  );
			}
			return of(); // Ne rien faire si aucun compte n'est disponible
		  })
		)
	  );

	loadAccountsDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.loadAccountsDetails),
			mergeMap((action) => {
				return this.getAccountDetailsUseCase.execute(action.accountID).pipe(
					map((account) =>
						AccountActions.loadAccountDetailsSuccess({
							account,
						})
					),
					catchError((error) =>
						of(AccountActions.loadAccountDetailsFailure({ error }))
					)
				);
			})
		)
	);
	generateReleveRibAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateReleveRibAccount),
			mergeMap((action) => {
				return this.generateReleveRibAccountUseCase
					.execute({ ...action })
					.pipe(
						map((response) =>
							AccountActions.generateReleveRibAccountSuccess({
								response,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateReleveRibAccountFailure({
									error,
								})
							)
						)
					);
			})
		)
	);

	generateReleveRib$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateReleveRib),
			mergeMap((action) => {
				return this.generateReleveRibUseCase
					.execute(action.iban)
					.pipe(
						map((response) =>
							AccountActions.generateReleveRibSuccess({
								fileId: response.fileId,
								fileName: response.fileName,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateReleveRibFailure({
									error,
								})
							)
						)
					);
			})
		)
	);

	generateAccountStatement$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateAccountStatement),
			mergeMap((action) => {
				return this.generateAccountStatementUseCase
					.execute({ ...action.data })
					.pipe(
						map((response) =>
							AccountActions.generateAccountStatementSuccess({
								fileId: response.fileId,
								fileName: response.fileName,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateAccountStatementFailure({
									error,
								})
							)
						)
					);
			})
		)
	);
	constructor(
		private readonly actions$: Actions,
		private readonly getAccountUseCase: GetAccountUseCase,
		private readonly getAccountDetailsUseCase: GetAccountDetailsUseCase,
		private readonly generateReleveRibUseCase: GenerateReleveRibUseCase,
		private readonly generateReleveRibAccountUseCase: GenerateReleveRibAccountUseCase,
		private readonly generateAccountStatementUseCase: GenerateAccountStatementUseCase
	) {}
}








