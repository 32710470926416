import { AlertPreferenceDTO, NNotificationDTO, NotificationCategryPDO, NotificationDTO } from '../dto/notification.dto';
import { AlertPreference, NNotification, Notificationcategory, NotificationCount } from '../../domain/entities/notification.entity';

export class NotificationMapper {
	static toDomain(dto: NotificationDTO): NotificationCount {
		return new NotificationCount(dto.UnreadNotificationCount);
	}
}


export class NNotificationMapper {
	static toDomain(dto: NNotificationDTO): NNotification {
		return new NNotification(
			dto.isRead,
			dto.notificationId,
			dto.notificationSubject,
			dto.notificationText,
			dto.receivedDate,
			dto.userNotificationId,
			dto.notificationCategory
		);
	}
}



export class NotificationCategoryMapper {
	static toDomain(dto: NotificationCategryPDO): Notificationcategory {
		return new Notificationcategory(
			dto.alertcategory_accountLevel,
			dto.alertcategory_Name,
			dto.alertcategory_status_id,
			dto.alertcategorytext_softdeleteflag,
			dto.Alerts_count,
			dto.alertcategory_companyLegalUnit,
			dto.alertcategory_softdeleteflag,
			dto.alertcategory_DisplaySequence,
			dto.Groups_count,
			dto.alertcategorytext_createdts,
			dto.categorySubscription,
			dto.alertcategorytext_LanguageCode,
			dto.alertcategory_id,
			dto.alertcategory_freqId,
			dto.alertcategorytext_synctimestamp,
			dto.alertcategory_freqTime,
			dto.alertcategorytext_lastmodifiedts,
			dto.alertcategorytext_DisplayName,
			dto.alertcategorytext_Description
		);
	}
}

export class AlertPreferenceMapper {
	static toDomain(dto: AlertPreferenceDTO): AlertPreference {
		return new AlertPreference(
			dto.alertTypes
		);
	}
}