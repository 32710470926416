import { Observable } from 'rxjs';
import { Transfer } from '../entities/transfer.entity';
import {
	InternationalTransferRequestDTO,
	RtgsTransferRequestDTO,
	SikaTransferRequestDTO,
	TransferRequestDto,
	TransferStandingOrderRequestDTO,
	TransferSicaStandingOrderRequestDTO,
	TransferInternationalStandingOrderRequestDTO,
	TransferDeleteRequestDTO,
	TransferDeleteResponseDTO,
	BaseCurrencyRequestDTO,
	BaseCurrencyResponseDTO,
	DashboardCurrencyListRequestDTO,
	DashboardCurrencyListResponseDTO,
	CurrencyRatesRequestDTO,
	CurrencyRatesResponseDTO,
	GetTransferAdviceRequestDTO,
} from '../../application/dtos/transfer.dto';
import { BankDate } from '../entities/bank-date.entity';
import { UemoaBank } from '../entities/uemoa-bank.entity';
import { RtgsTransfer } from '../entities/rtgs-transfer.entity';
import { TransferStandingOrder } from '../entities/transfer-standing-order.entity';
import { TransferHistory } from '../entities/transfer-history.entity';
import { TransferInstructions } from '../entities/transfer-instructions.entity';

export abstract class ITransferRepository {
	abstract getBankDate(): Observable<BankDate[]>;
	abstract transferToOwnerAccount(
		transfer: TransferRequestDto
	): Observable<Transfer>;
	abstract transferToIntraAccount(
		transfer: TransferRequestDto
	): Observable<Transfer>;
	abstract sikaTransfer(request: SikaTransferRequestDTO): Observable<Transfer>;
	abstract rtgsTransfer(
		request: RtgsTransferRequestDTO
	): Observable<RtgsTransfer>;
	abstract transferToInternational(
		request: InternationalTransferRequestDTO
	): Observable<Transfer>;
	abstract getTransferHistory(): Observable<TransferHistory[]>;
	abstract getTransferAdvice(request: GetTransferAdviceRequestDTO): Observable<{ fileId: string, fileName: string}>;
	abstract getUemoaBanks(): Observable<UemoaBank[]>;
	abstract createOwnAccountStandingOrder(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrder>;
	abstract createIntraBankStandingOrderTransfer(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrder>;
	abstract createSicaStandingOrderTransfer(
		request: TransferSicaStandingOrderRequestDTO
	): Observable<TransferStandingOrder>;
	abstract createInternationalStandingOrder(
		request: TransferInternationalStandingOrderRequestDTO
	): Observable<TransferStandingOrder>;
	abstract getInstructions(): Observable<TransferInstructions[]>;
	abstract internationalFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO>;
	abstract interBankFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO>;
	abstract intraBankAccFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO>;
	abstract transferToOwnAccountsDelete(
		data: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO>;
	abstract fetchBaseCurrency(
		data: BaseCurrencyRequestDTO
	): Observable<BaseCurrencyResponseDTO>;
	abstract fetchDashboardCurrencyList(
		data: DashboardCurrencyListRequestDTO
	): Observable<DashboardCurrencyListResponseDTO>;
	abstract fetchCurrencyRates(
		data: CurrencyRatesRequestDTO
	): Observable<CurrencyRatesResponseDTO>;
}
