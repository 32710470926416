
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { INotificationRepository } from '../../domain/repositories/notification.repository';
import { AlertPreference } from '../../domain/entities/notification.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAlertPreferencesUseCase {
	constructor(
		@Inject('INotificationRepository')
		private readonly notificationRepository: INotificationRepository
	) {}

	execute(): Observable<AlertPreference> {
		return this.notificationRepository.getAlertPreferences();
	}
}
