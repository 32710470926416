import { Inject, Injectable } from '@angular/core';
import { IMassTransferRepository } from '../../../domain/repositories/mass-transfer.repository';
import { PaginationRequestDTO } from '../../dtos/mass-transfer/uploaded-file.dto';

@Injectable({ providedIn: 'root' })
export class FetchBulkPaymentRecordOnGoingPaymentsUseCase {
    constructor(@Inject('IMassTransferRepository') private readonly massTransferRepository: IMassTransferRepository) { }

    execute(request: PaginationRequestDTO) {
        return this.massTransferRepository.fetchBulkPaymentRecordOnGoingPayments(request);
    }
}
