import { AccountDetail } from '../../domain/entities/account-details.entity';
import { AccountDetailsDto } from '../dtos/account-details.dto';

export class AccountDetailsMapper {
	static mapToDomain(accountDTO: AccountDetailsDto): AccountDetail {
		return new AccountDetail(
			accountDTO.accountID,
			accountDTO.Account_id,
			accountDTO.accountHolder,
			accountDTO.accountName,
			accountDTO.arrangementId,
			accountDTO.accountType,
			accountDTO.displayName,
			accountDTO.availableBalance,
			accountDTO.currencyCode,
			accountDTO.currentBalance,
			accountDTO.dividendLastPaidAmount,
			accountDTO.dividendPaidYTD,
			accountDTO.dividendRate,
			accountDTO.lastPaymentDate,
			accountDTO.nickName,
			accountDTO.openingDate,
			accountDTO.outstandingBalance,
			accountDTO.paymentDue,
			accountDTO.payoffAmount,
			accountDTO.pendingDeposit,
			accountDTO.pendingWithdrawal,
			accountDTO.principalBalance,
			accountDTO.routingNumber,
			accountDTO.supportBillPay,
			accountDTO.supportDeposit,
			accountDTO.supportTransferFrom,
			accountDTO.supportTransferTo,
			accountDTO.swiftCode,
			accountDTO.totalCreditMonths,
			accountDTO.totalDebitsMonth,
			accountDTO.transferLimit,
			accountDTO.supportChecks,
			accountDTO.IBAN,
			accountDTO.AccountName,
			accountDTO.blockedAmount,
			accountDTO.productId,
			accountDTO.approvedOverdraftLimit,
			accountDTO.maturityDate,
			accountDTO.interestRate,
			accountDTO.interestEarned,
			accountDTO.arrDormancyStatus,
			accountDTO.productName,
			accountDTO.sanctionedAmount,
			accountDTO.sanctionedDate,
			accountDTO.paidInstallmentsCount,
			accountDTO.futureInstallmentsCount,
			accountDTO.overDueInstallmentsCount,
			accountDTO.lastPaymentAmount,
			accountDTO.nextPaymentAmount,
			accountDTO.productDescription
		);
	}
}
