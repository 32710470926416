import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from '../actions/notification.actions';
import { AlertPreference, NNotification, Notificationcategory, NotificationCount } from '../../domain/entities/notification.entity';

export interface NotificationState {
	notifications: NotificationCount;
	list: NNotification[],
	categories: Notificationcategory[],
	readNotification: { totalRecords: number;updatedRecords: number;notificationId: any;} | null,
	counts : {unreadNotificationCount: string;}[],
	alertPreferences: AlertPreference | null,
	loading: boolean;
	error: any;
}

export const initialState: NotificationState = {
	notifications: { unreadNotificationCount: '' },
	list: [],
	categories: [],
	readNotification: null,
	counts: [],
	alertPreferences: null,
	loading: false,
	error: null,
};

export const notificationReducer = createReducer(
	initialState,
	
	on(NotificationActions.loadNotifications, (state) => ({
		...state,
		loading: true,
	})),
	on(
		NotificationActions.loadNotificationsSuccess,
		(state, { notifications }) => ({
			...state,
			notifications,
			loading: false,
			error: null,
		})
	),
	on(NotificationActions.loadNotificationsFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),
	on(NotificationActions.notificationCategory, (state) => ({
		...state,
		loading: true,
	})),
	on(NotificationActions.notificationCategorySuccess, (state, { categories }) => ({
		...state,
		categories: categories,
		loading: false,
		error: null
	})),
	on(NotificationActions.notificationCategoryFailure, (state, { error }) => ({
		...state,
		loading: false,
		error: error
	})),
	on(NotificationActions.notificationList, (state) => ({
		...state,
		loading: true,
	})),
	on(NotificationActions.notificationListSuccess, (state, { notifications }) => ({
		...state,
		list: notifications,
		loading: false,
		error: null
	})),
	on(NotificationActions.notificationListFailure, (state, { error }) => ({
		...state,
		loading: false,
		error: error
	})),

	on(NotificationActions.notificationCount, (state) => ({
		...state,
		loading: true,
	})),
	on(NotificationActions.notificationCountSuccess, (state, { counts }) => ({
		...state,
		counts: counts,
		loading: false,
		error: null
	})),
	on(NotificationActions.notificationCountFailure, (state, { error }) => ({
		...state,
		loading: false,
		error: error
	})),

	on(NotificationActions.readNotification, (state) => ({
		...state,
		readNotification: null,
		loading: true,
	})),
	on(NotificationActions.readNotificationSuccess, (state, response) => ({
		...state,
		readNotification: response.response,
		loading: false,
		error: null
	})),
	on(NotificationActions.readNotificationFailure, (state, { error }) => ({
		...state,
		readNotification: null,
		loading: false,
		error: error
	})),

	on(NotificationActions.getAlertPreferences, (state) => ({
		...state,
		loading: true,
	})),
	on(NotificationActions.getAlertPreferencesSuccess, (state, {alertPreferences}) => ({
		...state,
		alertPreferences: alertPreferences,
		loading: false,
		error: null
	})),
	on(NotificationActions.getAlertPreferencesFailure, (state, { error }) => ({
		...state,
		loading: false,
		error: error
	})),
);
