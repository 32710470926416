import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { GetTransferAdviceRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class GenerateTransferAdviceUseCase {
	transferService = inject(ITransferRepository);

	execute(request: GetTransferAdviceRequestDTO) {
		return this.transferService.getTransferAdvice(request);
	}
}
