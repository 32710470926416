import { Observable } from 'rxjs';
import {
	TransferRequestDTO,
	TransferDTO,
	BankDateDTO,
	SikaTransferRequestDTO,
	RtgsTransferRequestDTO,
	RtgsTransferDTO,
	InternationalTransferRequestDTO,
	TransferStandingOrderRequestDTO,
	TransferStandingOrderDTO,
	TransferSicaStandingOrderRequestDTO,
	TransferInternationalStandingOrderRequestDTO,
	TransferHistoryDTO,
	TransferInstructionsDTO,
	TransferDeleteRequestDTO,
	BaseCurrencyRequestDTO,
	BaseCurrencyResponseDTO,
	DashboardCurrencyListResponseDTO,
	DashboardCurrencyListRequestDTO,
	CurrencyRatesRequestDTO,
	CurrencyRatesResponseDTO,
	GetTransferAdviceRequestDTO,
} from '../../../application/dtos/transfer/transfer.dto';
import { UemoaBanksResponseDto } from '../../../application/dtos/transfer/uemoa-bank.dto';

export abstract class ITransferService {
	abstract createOwnAccountTransfer(
		request: TransferRequestDTO
	): Observable<TransferDTO>;
	abstract createIntraBankTransfer(
		request: TransferRequestDTO
	): Observable<TransferDTO>;
	abstract createSikaTransfer(
		request: SikaTransferRequestDTO
	): Observable<TransferDTO>;
	abstract createRtgsTransfer(
		request: RtgsTransferRequestDTO
	): Observable<RtgsTransferDTO>;
	abstract createInternationalTransfer(
		request: InternationalTransferRequestDTO
	): Observable<TransferDTO>;
	abstract createInternationalTransfer(
		request: InternationalTransferRequestDTO
	): Observable<TransferDTO>;
	abstract getBankDate(): Observable<BankDateDTO>;
	abstract getUemoaBanks(): Observable<UemoaBanksResponseDto>;

	abstract createOwnAccountStandingOrder(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO>;
	abstract createIntraBankStandingOrderTransfer(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO>;
	abstract createSicaStandingOrderTransfer(
		request: TransferSicaStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO>;
	abstract createInternationalStandingOrder(
		request: TransferInternationalStandingOrderRequestDTO
	): Observable<TransferStandingOrderDTO>;
	abstract getTransferHistory(): Observable<TransferHistoryDTO[]>;
	abstract getTransferAdvice(request: GetTransferAdviceRequestDTO): Observable<{ fileId: string; fileName: string; }>;
	abstract getInstructions(): Observable<TransferInstructionsDTO[]>;
	abstract internationalFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<any>;
	abstract interBankFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<any>;
	abstract intraBankAccFundTransferDelete(
		data: TransferDeleteRequestDTO
	): Observable<any>;
	abstract transferToOwnAccountsDelete(
		data: TransferDeleteRequestDTO
	): Observable<any>;
	abstract fetchBaseCurrency(
		data: BaseCurrencyRequestDTO
	): Observable<BaseCurrencyResponseDTO>;
	abstract fetchDashboardCurrencyList(
		data: DashboardCurrencyListRequestDTO
	): Observable<DashboardCurrencyListResponseDTO>;
	abstract fetchCurrencyRates(
		data: CurrencyRatesRequestDTO
	): Observable<CurrencyRatesResponseDTO>;
}
