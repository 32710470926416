import { Inject, Injectable } from '@angular/core';
import { IDiscussionRepository } from '../../domain/repositories/discussion.repository';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CreateMediaUseCase {
	constructor(
		@Inject('IDiscussionRepository')
		private readonly discussionRepository: IDiscussionRepository
	) {}

	execute(Name: string, Size: number): Observable<string> {
		return this.discussionRepository.createMedia(Name, Size);
	}
}
