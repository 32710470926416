import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IAccountRepository } from '../../domain/repositories/account.repository';
import {
	AccountGenerate
} from '../dtos/account-details.dto';

@Injectable({
	providedIn: 'root',
})
export class GenerateReleveRibUseCase {
	private readonly accountRepository = inject(IAccountRepository);

	execute(iban: string): Observable<AccountGenerate> {
		return this.accountRepository.generateReleveRib(iban);
	}
}
