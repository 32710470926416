import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as NotificationActions from '../actions/notification.actions';
import { GetNotificationsUseCase } from '../../application/use-cases/get-notifications.use-case';
import { NotificationCategoryUseCase } from '../../application/use-cases/notification-category.use-case';
import { NotificationListUseCase } from '../../application/use-cases/notification-list.use-case';
import { NotificationCountUseCase } from '../../application/use-cases/notification-count.use-case';
import { UserNotificationUseCase } from '../../application/use-cases/read-notification.use-case';
import { GetAlertPreferencesUseCase } from '../../application/use-cases/get-alert-preferences';

@Injectable()
export class NotificationEffects {
	loadNotifications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.loadNotifications),
			switchMap(() =>
				this.getNotificationsUseCase.execute().pipe(
					map((notifications) =>
						NotificationActions.loadNotificationsSuccess({
							notifications,
						})
					),
					catchError((error) =>
						of(
							NotificationActions.loadNotificationsFailure({
								error,
							})
						)
					)
				)
			)
		)
	);


	notificationList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.notificationList),
			switchMap(() =>
				this.notificationListUseCase.execute().pipe(
					map((notifications) =>
						NotificationActions.notificationListSuccess({
							notifications
						})
					),
					catchError((error) =>
						of(
							NotificationActions.notificationListFailure({
								error,
							})
						)
					)
				)
			)
		)
	);


	notificationCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.notificationCategory),
			switchMap(() =>
				this.notificationCategoryUseCase.execute().pipe(
					map((categories) =>
						NotificationActions.notificationCategorySuccess({
							categories
						})
					),
					catchError((error) =>
						of(
							NotificationActions.notificationCategoryFailure({
								error,
							})
						)
					)
				)
			)
		)
	);



	notificationCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.notificationCount),
			switchMap(() =>
				this.notificationCountUseCase.execute().pipe(
					map((counts) =>
						NotificationActions.notificationCountSuccess({
							counts
						})
					),
					catchError((error) =>
						of(
							NotificationActions.notificationCountFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	readNotification$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.readNotification),
			switchMap((actions) =>
				this.userNotificationUseCase.execute(actions.notificationId).pipe(
					map((response) =>
						NotificationActions.readNotificationSuccess({
							response
						})
					),
					catchError((error) =>
						of(
							NotificationActions.readNotificationFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	getAlertPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.getAlertPreferences),
			switchMap(() =>
				this.getAlertPreferencesUseCase.execute().pipe(
					map((alertPreferences) =>
						NotificationActions.getAlertPreferencesSuccess({
							alertPreferences
						})
					),
					catchError((error) =>
						of(
							NotificationActions.getAlertPreferencesFailure({
								error,
							})
						)
					)
				)
			)
		)
	)
	
	constructor(
		private readonly actions$: Actions,
		private readonly getNotificationsUseCase: GetNotificationsUseCase,
		private readonly  notificationCategoryUseCase: NotificationCategoryUseCase,
		private readonly notificationListUseCase: NotificationListUseCase,
		private readonly notificationCountUseCase : NotificationCountUseCase,
		private readonly userNotificationUseCase : UserNotificationUseCase,
		private readonly getAlertPreferencesUseCase : GetAlertPreferencesUseCase
	) {}
}
