import {
	AccountGenerate,
	GenerateAccountStatementRequestDTO,
} from '../../application/dtos/account-details.dto';
import { AccountDetail } from '../entities/account-details.entity';
import { Account } from '../entities/account.entity';
import { Observable } from 'rxjs';

export abstract class IAccountRepository {
	abstract getAccounts(): Observable<Account[]>;
	abstract getDetails(accountID: string): Observable<AccountDetail[]>;
	abstract generateReleveRib(
		iban: string
	): Observable<AccountGenerate>;
	abstract generateReleveRibAccount(data: {
		fileId: string;
		fileName: string;
	}): Observable<any>;
	abstract generateAccountStatement(
		data: GenerateAccountStatementRequestDTO
	): Observable<AccountGenerate>;
}
