import { createReducer, on } from "@ngrx/store";
import * as DiscussionActions from "../actions/discussion.actions";
import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";
import { Discussion } from "../../domain/entities/discussion.entity";

export interface DiscussionState {
  discussions: Discussion[];
  categories: DiscussionCategory[];
  mediaIds: string[];
  attachmentId: string;
  messageId: string | null;
  requestId: string | null;
  count: number;
  loading: boolean;
  error: any;
  response: any;
}

export const initialState: DiscussionState = {
  discussions: [],
  categories: [],
  messageId: null,
  requestId: null,
  mediaIds: [],
  attachmentId: '',
  count: 0,
  loading: false,
  error: null,
  response: null,
};

export const discussionReducer = createReducer(
  initialState,

  on(DiscussionActions.loadDiscussionCategories, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    DiscussionActions.loadDiscussionCategoriesSuccess,
    (state, { categories }) => ({
      ...state,
      categories,
      loading: false,
      error: null,
    })
  ),
  on(DiscussionActions.loadDiscussionCategoriesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),


  on(DiscussionActions.createDiscussion, state => ({ ...state, loading: true })),
  on(DiscussionActions.createDiscussionSuccess, (state, { discussion }) => ({
      ...state,
      response: discussion,
      loading: false,
      error: null
  })),
  on(DiscussionActions.createDiscussionFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),


  on(DiscussionActions.loadDiscussions, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    DiscussionActions.loadDiscussionsSuccess,
    (state, { discussions }) => ({
      ...state,
      discussions,
      loading: false,
      error: null,
    })
  ),
  on(DiscussionActions.loadDiscussionsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),


  on(DiscussionActions.activateDiscussion, state => ({ ...state, loading: true })),
  on(DiscussionActions.activateDiscussionSuccess, (state) => ({
      ...state,
      loading: false,
      error: null,
  })),
  on(DiscussionActions.activateDiscussionFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),
 
  on(DiscussionActions.deactivateDiscussion, state => ({ ...state, loading: true })),
  on(DiscussionActions.deactivateDiscussionSuccess, (state) => ({
      ...state,
      loading: false,
      error: null,
  })),
  on(DiscussionActions.deactivateDiscussionFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),

  on(DiscussionActions.deleteDiscussion, state => ({ ...state, loading: true })),
  on(DiscussionActions.deleteDiscussionSuccess, (state, { discussionId })  => ({
      ...state,
      loading: false,
      error: null,
      discussions: state.discussions.filter(discussion => discussion.id !== discussionId)
  })),
  on(DiscussionActions.deleteDiscussionFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),

  on(DiscussionActions.getUnreadDiscussionCount, state => ({ ...state, loading: true })),
  on(DiscussionActions.getUnreadDiscussionCountSuccess, (state, { count }) => ({
      ...state,
      count,
      loading: false,
      error: null
  })),
  on(DiscussionActions.getUnreadDiscussionCountFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),

  on(
    DiscussionActions.createMedia, 
    DiscussionActions.addAttachment,
    state => ({ ...state, loading: true })
  ),
  on(DiscussionActions.createMediaSuccess, (state, { mediaId }) => ({
      ...state,
      mediaIds: [...state.mediaIds, mediaId],
      loading: false,
      error: null
  })),
  on(DiscussionActions.createMediaFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  })),

  on(DiscussionActions.addAttachmentSuccess, (state, { attachmentId }) => ({
      ...state,
      attachmentId,
      loading: false,
      error: null 
  })),
  on(DiscussionActions.addAttachmentFailure, (state, { error }) => ({
      ...state,
      error,
      loading: false
  }))
);
